<template>
      <v-card v-if="menu">
        <v-toolbar
          dark
          color="primary"
          :elevation="0"
        >
          <v-toolbar-title>{{ !menu.ID ? 'Crear men�' : `Editar "${item.Title.es}"`  }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-layout wrap >
          <v-flex xs12 style="padding: 10px 30px">
            <translate
              v-model="menu.Title"
              :languages="languages"
              :label="$t('booking.name', locale)"
              @input="handleChangeIsModified"
              type="text"
            />
          </v-flex>
          <v-flex xs12 style="padding: 20px 30px 0 30px">
            <translate
              v-model="menu.Description"
              :languages="languages"
              :label="$t('booking.description', locale)"
              @input="handleChangeIsModified"
              type="textArea"
            />
          </v-flex>
          <v-flex xs4 style="padding: 0 5px 10px 20px">
            <v-btn
              @click="onClose"
              block
            >
              {{ $t('common.cancel', locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs4 style="padding: 0 5px 10px 10px">
            <v-btn
              @click="handleUpdateMenuCheck('hide')"
              color="red"
              dark
              block
            >
              {{ $t('common.hide', locale) }}
            </v-btn>
          </v-flex>
          <v-flex xs4 style="padding: 0 20px 10px 5px">
            <v-btn
              @click="handleUpdateMenuCheck('show')"
              color="primary"
              block
            >
              {{ $t('common.show', locale) }}
            </v-btn>
          </v-flex>
          <!--v-flex xs12>
            <action-buttons
              :id="menu.ID"
              :onDeleteItem="showDelete ? (v) => { handleDelete(v) }:null"
              :onSaveItem="handleSave"
              :onClose="onClose"
              :locale="locale"
            />
          </v-flex-->
        </v-layout>
      </v-card>
</template>
<script>
import api from '@/services/api'
import Translate from '@/components/Translate'
//import ActionButtons from '@/components/ActionButtons'

export default {
  components: {
    Translate,
    //ActionButtons,
  },
  props: {
    newItemParentID: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      required: {},
    },
    onClose: {
      type: Function,
      default: null,
    },
    workspaceID: {
      type: String,
      default: null,
    }, 
  },
  data () {
    return {
      showDelete: false,
      locale: 'es',
      languages: [ 'es', 'en', 'de', 'fr'],
      dialog: false,
      menu: null,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  watch: {
    item () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.showDelete = this.item.ID !== null
      this.menu = JSON.parse(JSON.stringify(this.item))
    },
    handleChangeIsModified () {

    },
    handleDelete (v) {
      api.deleteItem('turismo', `v1/public/turismo-local/`, v)
        .then(() => {
          this.onClose('delete', this.menu)
        })
    },
    handleSave () {
      if (this.menu.ID) {
        api.updateItem('turismo', `v1/public/turismo-local/`, this.menu.ID, this.menu)
          .then(() => {
            this.onClose('update', this.menu)
          })
      } else {
        //this.menu.ParentID = this.newItemParentID
        if (this.menu.ParentID)
          api.addItem('turismo', `v1/public/turismo-local/`, this.menu)
            .then(() => {
              this.dialog = false
              this.onClose('add', this.menu)
            })
      }
    },
    handleUpdateMenuCheck (action) {
      if (!this.workspaceID) return
      api.updateItem('turismo', `v1/public/turismo-local/`, this.menu.ID.concat('/menu-check'), { action, workspaceID: this.workspaceID })
        .then(() => {
          this.onClose(action)
        })
    }
  },
}
</script>

