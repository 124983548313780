<template>
  <div>
  <v-layout wrap v-if="menus && menus.length > 0">
    <v-flex xs12
      v-for="(item) in menus"
      :key="item.ID"
      style="margin-bottom:1px;"
    >
      <v-toolbar dense 
        elevation="0"
      >
        <!--v-app-bar-nav-icon></v-app-bar-nav-icon-->

        <v-toolbar-title
          @click="onEdit(item)"
          style="cursor: pointer;"
        >
          {{ getTranslation(item.Title, 'es') }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn icon
          @click="onEdit(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>
    </v-flex>
  </v-layout>
  <v-layout wrap v-else>
    <v-flex xs12>
      <v-alert
        dense
        outlined
        type="warning"
        color="primary"
      >
        No hay elementos pendientes de verificar.
      </v-alert>
    </v-flex>
  </v-layout>
  </div>
</template>
<script>
import utils from '@/services/utils'
export default {
  name: 'MenuCheckList',
  components: {
  },
  props: {
    menus: {
      type: Array,
      default: null,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  watch: {
    workspaceID () {
    },
  },
  mounted () {
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
  },
}
</script>

