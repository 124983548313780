<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 10px 20px 20px;">
      <menu-check-list
        :menus="menus"
        :onEdit="handleEdit"
        style="height:calc(100vh - 150px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
        :workspaceID="workspaceID"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 20px 10px">
      <v-card
        style="min-height:calc(100vh - 104px);"
      >
        <v-card-text>
          <no-edited
            v-if="!editedItem"
            :locale="locale"
          />
          <menu-check-form 
            v-else-if="editedItem && workspaceID"
            :item="editedItem" 
            :onClose="handleClose"
            :workspaceID="workspaceID"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import NoEdited from './NoEdited'
import MenuCheckList from './MenuCheckList'
import MenuCheckForm from './MenuCheckForm'
export default {
  components: {
    NoEdited,
    MenuCheckList,
    MenuCheckForm,
  },
  data: () => ({
    editedItem: null,
    menus: [],
  }),
  computed: {
    ...mapState('app',['locale', 'turismoWorkspace']),
    workspaceID () {
      return this.turismoWorkspace
    },
  },
  watch: {
    'turismoWorkspace' () {
      this.handleGetMenuCheck()
    },
  },
  mounted () {
    this.handleGetMenuCheck()
  },
  methods: {
    handleGetMenuCheck () {
      api.getAllWithoutLimit ('turismo', `v1/private/workspaces/${this.workspaceID}/menu-check`)
        .then(response => {
          console.log('response', response)
          this.menus = response.map(item => {
            item.HideWorkspaces = item.HideWorkspaces ? JSON.parse(item.HideWorkspaces) : []
            const aux = item.HideWorkspaces && item.HideWorkspaces.filter(x => x.WorkspaceID === this.workspaceID).shift()
            item.HideWorkspace = aux && aux.Hide === true ? true : false
            item.Title = item.Title ? JSON.parse(item.Title) : null
            item.Description = item.Description ? JSON.parse(item.Description) : null
            item.Service = item.Service ? JSON.parse(item.Service) : null
            return item
          })
        })
    },
    handleClose (action) {
      if (action) this.handleGetMenuCheck()
      this.editedItem = null
    },
    handleEdit (v) {
      if (!v) return
      this.editedItem = v
    },
  },
}
</script>

